<template>
  <div class="novel-card">
    <img :src="novel.coverUrl" alt="封面" class="cover">
    <div class="info">
      <h3>{{ novel.title }}</h3>
      <p v-if="novel.authors">作者: {{ novel.authors }}</p>
      <p v-if="novel.latestUpdate">最新章节: {{ novel.latestUpdate }}</p>
      <p v-if="novel.score">评分: {{ Math.round(novel.score) }}</p>
      <p> {{ novel.brief }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ['novel', 'host'],
}
</script>

<style scoped>
.novel-card {
  border: 1px solid #eee;
  padding: 10px;
  margin-bottom: 10px;
}

.cover {
  width: 100px;
  height: 140px;
  object-fit: cover;
}

.info {
  margin-left: 10px;
  box-sizing: border-box;
  width: 70%; /* 或其他固定宽度 */
  height: 140px;
  overflow-x: auto;
}

.cover, .info {
  display: inline-block;
  vertical-align: top;
}

.info > p, .info > h3 {
  margin: 0;
  white-space: normal; /* 默认值，自动换行 */
  /* 或者 */
  white-space: pre-wrap;
}

.info > p {
  margin-top: 6px;
}
</style>